import React from "react"
import isEqual from "lodash/isEqual"
import type { ChallengesPageType } from "../types/ChallengesPageType"
import type { AllLayoutsType } from "@lesmills/gatsby-theme-common"
import { graphql } from "gatsby"
import { renderSEO, ROUTES } from "@lesmills/gatsby-theme-common"

import { withPreview } from "gatsby-source-prismic"

import Unsubscribe from "../components/Unsubscribe"

type Props = {|
  pageContext: {
    lang: string,
    data: ChallengesPageType,
    layout: AllLayoutsType,
  },
  location: Object,
|}

const UnsubscribeChallengesPage = (props: Props) => {
  const { pageContext, data, location } = props || {}

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const prismicChallengesPage = data.prismicChallengesPage || {}
  const pageContent = prismicChallengesPage.data

  return (
    <>
      {renderSEO(pageContent, ROUTES(lang).UNSUBSCRIBE_CHALLENGES, lang)}
      <Unsubscribe data={pageContent} lang={lang} location={location} />
    </>
  )
}

export const query = graphql`
  query($lang: String) {
    prismicChallengesPage(lang: { eq: $lang }) {
      data {
        header_logo {
          alt
          url
          thumbnails {
            Black {
              alt
              url
            }
          }
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_description {
          text
          raw
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        unsubscribe_hero_image {
          alt
          url
          thumbnails {
            Tablet {
              alt
              url
            }
          }
        }
        unsubscribe_hero_video {
          raw
        }
        unsubscribe_title {
          raw
          text
        }
        unsubscribe_message {
          raw
          text
        }
        unsubscribe_success_title {
          raw
          text
        }
        unsubscribe_success_message {
          raw
          text
        }
        unsubscribe_error_title {
          raw
          text
        }
        unsubscribe_error_message {
          raw
          text
        }
        unsubscribe_button_label {
          raw
          text
        }
        unsubscribe_error_redirect {
          raw
          text
        }
        redirect_challenges_button_label {
          text
        }
        lmod_challenges_invalid_token {
          raw
          text
        }
        lmod_challenges_segment_error {
          raw
          text
        }
        lmod_challenges_unsubscribe_challenge_unhandled_error {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(
  React.memo(UnsubscribeChallengesPage, (prevProps, nextProps) =>
    isEqual(prevProps.data, nextProps.data)
  )
)
