import React, { useState } from "react"
import isEqual from "lodash/isEqual"
import {
  handleErrorWithPrismic,
  SENTRY_ACTIONS,
  Icon,
  getParamsFromURL,
  isBrowser,
  ROUTES,
  linkResolver,
  htmlSerializerUpdateStyle,
  publicAPI,
  Spinner,
} from "@lesmills/gatsby-theme-common"
import { UnsubscribeChallengeEmails } from "../../graphql/challenges"

import type { ChallengesPageType } from "../../types/ChallengesPageType"

import loadable from "@loadable/component"

const Button = loadable(() => import("@lesmills/gatsby-theme-common"), {
  resolveComponent: components => components.Button,
  fallback: <Spinner />,
})

const RichText = loadable(() => import("prismic-reactjs"), {
  resolveComponent: components => components.RichText,
  fallback: <Spinner />,
})

type Props = {|
  data: ChallengesPageType,
  location: Object,
  lang: string,
|}

const Unsubscribe = ({ data }: Props) => {
  const {
    lmod_challenges_unsubscribe_challenge_unhandled_error = {},
    header_logo = {
      thumbnails: {
        Black: {
          url: "",
          alt: "",
        },
      },
    },
    unsubscribe_hero_image = {
      url: "",
      alt: "",
      thumbnails: {
        Tablet: {
          url: "",
          alt: "",
        },
      },
    },
    unsubscribe_hero_video = {},
    unsubscribe_title = {},
    unsubscribe_message = {},
    unsubscribe_success_title = {},
    unsubscribe_success_message = {},
    unsubscribe_error_title = {},
    unsubscribe_error_message = {},
    unsubscribe_button_label = {},
    redirect_challenges_button_label = {},
  } = data || {}

  const heroImageThumbnails = unsubscribe_hero_image.thumbnails || {}
  const headerLogoThumbnails = header_logo.thumbnails || {}
  const unsubscribeHeroVideo =
    unsubscribe_hero_video &&
    unsubscribe_hero_video.raw &&
    unsubscribe_hero_video.raw.url

  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [processing, setProcessing] = useState(false)
  const userToken = getParamsFromURL("userToken")

  const handleError = errors => {
    handleErrorWithPrismic(
      errors && errors.length > 0 ? errors : [],
      lmod_challenges_unsubscribe_challenge_unhandled_error.text,
      ({ message }) => setError(message),
      data,
      SENTRY_ACTIONS.UNSUBSCRIBE_CHALLENGE
    )
  }

  const unsubscribeChallenge = async token => {
    try {
      setProcessing(true)

      await publicAPI(
        UnsubscribeChallengeEmails,
        response => {
          const responseData = response.data
          const { errors } = responseData
          const successData = responseData.data || {}

          if (successData.unsubscribeChallengeEmails) {
            setSuccess(true)
          }

          if (errors && errors.length > 0) {
            handleError(errors)
            return
          }
        },
        handleError,
        {
          unsubscribeToken: token,
        }
      )
    } catch (exceptionError) {
      handleError(exceptionError.errors || [])
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div className="unsubscribe-wrapper relative w-full h-100vh p-24 flex flex-col justify-between md:p-0 md:h-auto md:justify-start">
      <div className="unsubscribe-container flex flex-col">
        {/* Hero image absolute */}
        <div className="challenge-unsubscribe-hero">
          {unsubscribeHeroVideo ? (
            <video
              autoPlay="autoPlay"
              loop="loop"
              muted="muted"
              playsInline="playsInline"
              className="top-0 left-0 object-cover unsubscribe-challenge-hero-bg hidden md:block md:w-full md:h-challenge-unsubscribe-hero"
            >
              <source src={unsubscribeHeroVideo} type="video/mp4"></source>
            </video>
          ) : (
            <picture>
              <source
                srcSet={unsubscribe_hero_image.url}
                media="(min-width: 1025px)"
              />
              <img
                alt={heroImageThumbnails.Tablet.alt}
                src={heroImageThumbnails.Tablet.url}
                className="unsubscribe-challenge-hero-bg object-cover hidden md:block md:w-full md:h-challenge-unsubscribe-hero"
                data-cy="unsubscribe_hero_image"
              />
            </picture>
          )}
          <picture>
            <source srcSet={header_logo.url} media="(min-width: 768px)" />
            <img
              alt={headerLogoThumbnails.Black.alt}
              src={headerLogoThumbnails.Black.url}
              className="object-cover top-0 left-0 w-challenge-unsubscribe-logo h-challenge-unsubscribe-logo mt-32 z-xs md:absolute md:ml-25 md:w-challenge-unsubscribe-logo-md md:h-challenge-unsubscribe-logo-md"
              data-cy="unsubscribe_logo"
            />
          </picture>
        </div>

        {/* Content */}
        <div
          className={`unsubscribe-content-wrapper mt-60 mx-auto w-full h-full md:pb-48 md:text-center md:flex md:flex-col md:max-w-challenge-unsubscribe-desc-md ${
            success || error ? "text-center md:mt-30" : "text-left md:mt-122"
          }`}
        >
          <div className="unsubscribe-content flex flex-col justify-between md:block">
            <div className="unsubscribe-description">
              {(success || error) && (
                <div
                  className={`unsubscribe-icon ${
                    success
                      ? "w-challenge-success-icon h-challenge-success-icon rounded-full bg-green-600 flex flex-col items-center justify-center"
                      : ""
                  } mb-30 mx-auto`}
                >
                  <Icon
                    icon={error ? "alert" : "check-mark"}
                    type="base"
                    classNames={`${
                      error
                        ? "alert-icon-md"
                        : "check-mark-icon-large unsubscribe-success-icon"
                    }`}
                  />
                </div>
              )}
              <RichText
                render={
                  success
                    ? unsubscribe_success_title.raw
                    : error
                    ? unsubscribe_error_title.raw
                    : unsubscribe_title.raw
                }
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializerUpdateStyle(
                  "",
                  "uppercase font-primary mb-22 text-6xl leading-32 md:text-9xl md:leading-40"
                )}
              />
              <RichText
                render={
                  success
                    ? unsubscribe_success_message.raw
                    : error
                    ? unsubscribe_error_message.raw
                    : unsubscribe_message.raw
                }
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializerUpdateStyle(
                  "",
                  "font-base-light text-gray-800 leading-20 text-3xs mb-40 md:text-2lg md:leading-24 md:mb-54 lg:mb-10"
                )}
              />
            </div>

            {!error && (
              <Button
                handleOnClick={() => {
                  if (userToken) {
                    if (success) {
                      isBrowser && (window.location.href = ROUTES().CHALLENGES)
                    } else {
                      unsubscribeChallenge(userToken)
                    }
                  }
                }}
                disabled={processing}
                submitting={processing}
                className={`btn btn-primary py-3 mb-46 mt-25 w-full mx-auto md:mb-0 md:max-w-335 ${
                  success ? "" : "bg-red-600"
                }`}
                testId="unsubscribe-challenge-btn"
              >
                {success
                  ? redirect_challenges_button_label.text
                  : unsubscribe_button_label.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Unsubscribe, (prevProps, nextProps) =>
  isEqual(prevProps.data, nextProps.data)
)
